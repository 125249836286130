import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ProgressBar from 'react-customizable-progressbar'
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/AboutMeStyle.js";

const useStyles = makeStyles(styles);

export default function AboutMeSection() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer justify="center" spacing={3}>
                <GridItem xs={12} sm={12} >
                    <h2 className={classes.title}>Let{"'"}s talk Skills</h2>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={80}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"C"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={70}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"C++"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={85}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"Python"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={68}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"JavaScript"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={85}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"Linux"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={65}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"C#"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={60}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"POSIX"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={70}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"RTOS"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={85}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"RestAPI"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={77}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"React"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={64}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"AWS"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={60}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"Shell"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={85}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"Electronics"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={85}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"Analytics"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
                <GridItem xs={3}>
                    <ProgressBar
                        radius={100}
                        progress={50}
                        strokeWidth={18}
                        strokeColor="#5d9cec"
                        strokeLinecap="round"
                        trackStrokeWidth={18}
                    >
                        <div className={classes.title}>
                            <div>{"Web Development"}</div>
                        </div>
                    </ProgressBar>
                </GridItem>
            </GridContainer>
            <div>
                {/* <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Free Chat"
                            description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                            icon={Chat}
                            iconColor="info"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Verified Users"
                            description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                            icon={VerifiedUser}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Fingerprint"
                            description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                            icon={Fingerprint}
                            iconColor="danger"
                            vertical
                        />
                    </GridItem>
                </GridContainer> */}
            </div>
        </div>
    );
}
